<template>
  <div class="card">
    <div class="card-body">
      <SubNav :sub-nav-list="index_menu_lists"></SubNav>
    </div>
    <DataTable
      :resources="contracts"
      :paginate="paginate"
      :striped-class="false"
    >
      <template #actions>
        <div class="px-1">
          <Link
            :href="`/organizations/${organization_id}/contracts/new`"
            class="btn btn-sm btn-primary"
          >
            <IconSvg icon="new" class="w-4 h-4"></IconSvg>
            {{
              $t("helpers.new", {
                model: $t("activerecord.models.client/contract"),
              })
            }}
          </Link>
        </div>
      </template>
      <template #thead>
        <DataTableHead
          v-for="thead in theads"
          :id="thead.id"
          :text="$t(thead.i18n_key)"
          :defaultOrderColumn="thead.defaultOrderColumn"
          :disableOrder="thead.disableOrder"
          class="px-5"
        >
        </DataTableHead>
        <DataTableHead
          id="actions"
          :text="$t('helpers.actions')"
          :disableOrder="true"
        >
        </DataTableHead>
      </template>
      <template #tbody-multi-tr="{ resources }">
        <ContractItem
          v-for="(resource, index) in resources"
          :key="index"
          :organization_id="organization_id"
          :contract="resource"
          :index="index"
          :opened-contract-id="openedContractId"
          :all_organizations="all_organizations"
          @toggleContract="toggleContract"
        />
      </template>
    </DataTable>
  </div>
</template>
<script setup>
import { ref } from "vue";
import ContractItem from "./_item.vue";

const props = defineProps({
  organization_id: String,
  contracts: Array,
  paginate: Object,
  index_menu_lists: Array,
  all_organizations: Array,
});

const theads = [
  {
    id: "name",
    i18n_key: "client/contract.index.name",
    disableOrder: true,
  },
  {
    id: "status",
    i18n_key: "client/contract.index.status",
    disableOrder: true,
  },
  {
    id: "pricing_item",
    i18n_key: "client/contract.index.pricing_item",
    disableOrder: true,
  },
  {
    id: "trade_tier",
    i18n_key: "client/contract.index.trade_tier",
    disableOrder: true,
  },
  {
    id: "user",
    i18n_key: "client/contract.index.user",
    disableOrder: true,
  },
  {
    id: "cloud_account",
    i18n_key: "client/contract.index.cloud_account",
    disableOrder: true,
  },
  {
    id: "comment",
    i18n_key: "client/contract.index.comment",
    disableOrder: true,
  },
];

const openedContractId = ref([]);

const toggleContract = (id) => {
  if (openedContractId.value.includes(id)) {
    openedContractId.value = openedContractId.value.filter(
      (item) => item !== id
    );
  } else if (openedContractId.value.length <= 1) {
    openedContractId.value.push(id);
  }
};
</script>

<template>
  <form @submit.prevent="formSubmit">
    <div class="card">
      <div class="card-body">
        <TabNav :tab-list="sub_menu_lists" class="py-0">
          <template #sub-content>
            <div class="flex items-center gap-2">
            <h5 class="font-bold">
              {{ $t("activerecord.attributes.organization.name") }}：{{
                organization.name
              }}
            </h5>
            <IconSvg icon="arrow_right" class="w-4 h-4 fill-black"></IconSvg>
            <h5 class="font-bold">
              {{ $t("activerecord.attributes.client/contract.name") }}：{{
                contract.name
              }}
            </h5>
            </div>
          </template>
          <TabInfo
            :contract="contract"
            :pricing_item="form.pricing_item"
            :exclude_discounts="exclude_discounts"
            :options_for_select="options_for_select"
            :new_pricing_item_cloud="new_pricing_item_cloud"
            :new_special_discount="new_special_discount"
            @submit="modifyPricingItem"
          />
        </TabNav>
      </div>

      <div class="card-footer">
        <FormAction
          :can-delete="false"
          :can-submit="validate(form.pricing_item)"
          :back-url="`/organizations/${organization.id}/contracts/${contract.id}/pricing_items`"
          :has-modified="form.isDirty"
          :submit-text="$t('helpers.create')"
          :processing="form.processing"
        />
      </div>
    </div>
  </form>
</template>
<script setup>
import { useForm } from "@inertiajs/vue3";
import FormAction from "@/components/form_action.vue";
import TabInfo from "./_tab_info.vue";
import { validate } from "./validate";

const props = defineProps({
  organization: Object,
  contract: Object,
  pricing_item: Object,
  exclude_discounts: Array,
  new_pricing_item_cloud: Object,
  new_special_discount: Object,
  options_for_select: Object,
  sub_menu_lists: Array,
});

const form = useForm({
  pricing_item: props.pricing_item,
});

const modifyPricingItem = (new_value) => {
  Object.assign(form.pricing_item, new_value);
};

const formSubmit = () => {
  // remove form.pricing_item.pricing_item_clouds_attributes key id
  form.pricing_item.pricing_item_clouds_attributes = form.pricing_item.pricing_item_clouds_attributes.map(
    (cloud) => {
      delete cloud.id;
      return cloud;
    }
  );


  form.post(
    `/organizations/${props.organization.id}/contracts/${props.contract.id}/pricing_items`
  );
};
</script>

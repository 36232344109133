<template>
  <div
    :id="`pricing_item_cloud-tabs-${pricing_item_cloud.id}`"
    role="tabpanel"
    :aria-labelledby="`pricing_item_cloud-tabs-item-${pricing_item_cloud.id}`"
    :class="{
      hidden: index != 0 || pricing_item_cloud._destroy == 1,
    }"
  >
    <div class="space-3">
      <dl class="grid grid-cols-10 gap-5 my-5">
        <dt class="flex items-center col-span-1">
          <label
            :for="`pricing_item_cloud_currency-${pricing_item_cloud.id}`"
            class="ml-3 required"
          >
            {{
              $t("activerecord.attributes.client/pricing_item_cloud.currency")
            }}
          </label>
        </dt>
        <dd class="flex items-center col-span-1">
          <multiselect
            v-model="form.pricing_item_cloud.currency"
            :options="props.options_for_select.currencies"
            :show-labels="false"
            :allow-empty="false"
            :max-height="120"
            :placeholder="$t('helpers.select')"
            class="!min-w-[auto]"
            :id="`pricing_item_cloud_currency-${pricing_item_cloud.id}`"
          >
            <template #singleLabel="props">
              {{ $t(`client/pricing_item_cloud.currency.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`client/pricing_item_cloud.currency.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </dd>
        <dd class="col-span-5">
          <dl class="grid grid-cols-10 gap-5">
            <dt class="flex items-center col-span-1">
              <label
                :for="`pricing_item_cloud_exchange_rate-${pricing_item_cloud.id}`"
                class="ml-3 required"
              >
                {{
                  $t(
                    "activerecord.attributes.client/pricing_item_cloud.exchange_rate"
                  )
                }}
              </label>
            </dt>
            <dd class="col-span-4">
              <multiselect
                v-model="form.pricing_item_cloud.exchange_rate"
                :options="props.options_for_select.exchange_rates"
                :show-labels="false"
                :allow-empty="false"
                :max-height="120"
                class="!min-w-[auto]"
                :id="`pricing_item_cloud_exchange_rate-${pricing_item_cloud.id}`"
                :placeholder="$t('helpers.select')"
              >
                <template #singleLabel="props">
                  {{
                    $t(
                      `client/pricing_item_cloud.exchange_rate.${props.option}`
                    )
                  }}
                </template>
                <template #option="props">
                  {{
                    $t(
                      `client/pricing_item_cloud.exchange_rate.${props.option}`
                    )
                  }}
                </template>
                <template #caret>
                  <IconSvg
                    icon="arrow_down"
                    class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
                  ></IconSvg>
                </template>
              </multiselect>
            </dd>
            <dd class="col-span-2">
              <span class="flex items-center">
                <input
                  v-model="form.pricing_item_cloud.exchange_rate_percentage"
                  type="number"
                  @wheel="$event.target.blur()"
                  class="input flex-1 !min-w-[auto]"
                  :id="`pricing_item_cloud_exchange_rate_fix-${pricing_item_cloud.id}`"
                  :disabled="
                    form.pricing_item_cloud.exchange_rate !== 'fix_rate'
                  "
                />%
              </span>
            </dd>

            <dt class="flex items-center col-span-1">
              <label
                :for="`pricing_item_cloud_tax_category-${pricing_item_cloud.id}`"
                class="ml-3 required"
              >
                {{
                  $t(
                    "activerecord.attributes.client/pricing_item_cloud.tax_category"
                  )
                }}
              </label>
            </dt>
            <dd class="flex items-center col-span-2">
              <multiselect
                v-model="form.pricing_item_cloud.tax_category"
                :options="props.options_for_select.tax_categories"
                :show-labels="false"
                :allow-empty="false"
                :max-height="120"
                :placeholder="$t('helpers.select')"
                class="!min-w-[auto]"
                :id="`pricing_item_cloud_tax_category-${pricing_item_cloud.id}`"
              >
                <template #singleLabel="props">
                  {{
                    $t(`client/pricing_item_cloud.tax_category.${props.option}`)
                  }}
                </template>
                <template #option="props">
                  {{
                    $t(`client/pricing_item_cloud.tax_category.${props.option}`)
                  }}
                </template>
                <template #caret>
                  <IconSvg
                    icon="arrow_down"
                    class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
                  ></IconSvg>
                </template>
              </multiselect>
            </dd>
          </dl>
        </dd>
        <dd class="col-span-3"></dd>
        <dt class="flex items-center col-span-1">
          <label
            :for="`pricing_item_cloud_client_support-${pricing_item_cloud.id}`"
            class="ml-3 required"
          >
            {{
              $t(
                "activerecord.attributes.client/pricing_item_cloud.client_support"
              )
            }}
          </label>
        </dt>
        <dd class="col-span-1">
          <multiselect
            v-model="form.pricing_item_cloud.client_support"
            :options="props.options_for_select.client_supports"
            :show-labels="false"
            :allow-empty="false"
            :taggable="true"
            @tag="addClientSupport"
            :max-height="120"
            :placeholder="$t('helpers.select')"
            class="!min-w-[auto]"
            :id="`pricing_item_cloud_client_support-${pricing_item_cloud.id}`"
          >
            <template #singleLabel="props">
              {{
                $t(`client/pricing_item_cloud.client_support.${props.option}`)
              }}
            </template>
            <template #option="props">
              {{
                $t(`client/pricing_item_cloud.client_support.${props.option}`)
              }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-white"
              ></IconSvg>
            </template>
          </multiselect>
        </dd>

        <dt class="flex items-center col-span-1">
          <label
            :for="`pricing_item_cloud_email-${pricing_item_cloud.id}`"
            class="ml-3 required"
          >
            {{
              $t(
                "activerecord.attributes.client/pricing_item_cloud.client_support_way"
              )
            }}
          </label>
        </dt>
        <dd class="flex items-center col-span-3 gap-1">
          <label
            :for="`checked_pricing_item_cloud_email-${pricing_item_cloud.id}`"
            class="custom-checkbox"
          >
            <input
              type="checkbox"
              v-model="checkedClientSupportEmail"
              :id="`checked_pricing_item_cloud_email-${pricing_item_cloud.id}`"
            />
            {{ $t("client/pricing_item_cloud.client_support_way.email") }}
            <span class="checkmark"></span>
          </label>
          <input
            type="email"
            :disabled="!checkedClientSupportEmail"
            v-model="form.pricing_item_cloud.client_support_way.email"
            :id="`pricing_item_cloud_email-${pricing_item_cloud.id}`"
            class="input flex-1 !min-w-[auto]"
          />
        </dd>
        <dd class="flex items-center col-span-4">
          <div class="grid grid-cols-5 gap-1">
            <div class="flex items-center justify-end col-span-1">
              <label
                :for="`pricing_item_cloud_group-${pricing_item_cloud.id}`"
                class="custom-checkbox"
              >
                <input
                  type="checkbox"
                  v-model="checkedClientSupportGroup"
                  :id="`pricing_item_cloud_group-${pricing_item_cloud.id}`"
                />
                {{ $t("client/pricing_item_cloud.client_support_way.group") }}
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="flex items-center col-span-2">
              <multiselect
                v-model="form.pricing_item_cloud.client_support_way.group.sms"
                :options="props.options_for_select.client_support_groups"
                :show-labels="false"
                :searchable="false"
                :allow-empty="false"
                :max-height="120"
                :placeholder="$t('helpers.select')"
                :disabled="!checkedClientSupportGroup"
                class="!min-w-[auto]"
              >
                <template #singleLabel="props">
                  {{
                    $t(
                      `client/pricing_item_cloud.client_support_group.${props.option}`
                    )
                  }}
                </template>
                <template #option="props">
                  {{
                    $t(
                      `client/pricing_item_cloud.client_support_group.${props.option}`
                    )
                  }}
                </template>
                <template #caret>
                  <IconSvg
                    icon="arrow_down"
                    class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
                  ></IconSvg>
                </template>
              </multiselect>
            </div>
            <div class="flex items-center col-span-2">
              <input
                v-model="form.pricing_item_cloud.client_support_way.group.name"
                type="text"
                :id="`pricing_item_cloud_group_name-${pricing_item_cloud.id}`"
                class="input !min-w-[auto]"
                :disabled="!checkedClientSupportGroup"
              />
            </div>
          </div>
        </dd>

        <dt class="flex items-center col-span-1">
          <label
            :for="`pricing_item_cloud_service_fee_percent-${pricing_item_cloud.id}`"
            class="ml-3 required"
          >
            {{
              $t(
                "activerecord.attributes.client/pricing_item_cloud.service_fee_percent"
              )
            }}
          </label>
        </dt>
        <dd class="flex items-center col-span-1 gap-1">
          <span
            v-t="'client/pricing_item_cloud.form.service_fee_percent_prefix'"
          ></span>
          <input
            v-model="form.pricing_item_cloud.service_fee_percent"
            type="number"
            @wheel="$event.target.blur()"
            class="!min-w-[auto] flex-1 input"
            :id="`pricing_item_cloud_service_fee_percent-${pricing_item_cloud.id}`"
            placeholder="0.00"
            min="0"
          />
          <span>%</span>
        </dd>

        <dt class="flex items-center justify-end col-span-1">
          <label
            :for="`pricing_item_cloud_common_discount_percent-${pricing_item_cloud.id}`"
            class="ml-3 required"
          >
            {{
              $t(
                "activerecord.attributes.client/pricing_item_cloud.common_discount_percent"
              )
            }}
          </label>
        </dt>
        <dd class="flex items-center col-span-1 gap-1">
          <span>-</span>
          <input
            v-model="form.pricing_item_cloud.common_discount_percent"
            type="number"
            min="0"
            class="!min-w-[auto] flex-1 input"
            placeholder="0.00"
            @wheel="$event.target.blur()"
            :id="`pricing_item_cloud_common_discount_percent-${pricing_item_cloud.id}`"
          />
          <span>%</span>
        </dd>
        <dd class="col-span-2">
          <ConfirmModal
            :button-text="`${$t(
              'client/pricing_item_cloud.form.exclude_discount.button'
            )}${selectedExcludeDiscountsCount}`"
            :modal-cancel-text="$t('helpers.back')"
            modalSubmitClass="btn btn-sm btn-primary"
            class="btn btn-primary btn-sm"
          >
            <template #modelHeader>
              <div class="flex items-center justify-between px-4 py-3">
                <h3 class="text-xl font-bold text-gray-80">
                  {{
                    $t(
                      "client/pricing_item_cloud.form.exclude_discount.modal.title"
                    )
                  }}
                </h3>

                <div class="relative">
                  <label
                    :for="`search-exclude-discount-${pricing_item_cloud.id}`"
                    class="relative block"
                  >
                    <IconSvg
                      icon="search"
                      class="w-4 h-4 fill-gray-80 with-input"
                    ></IconSvg>

                    <input
                      v-model="searchExcludeDiscounts"
                      type="text"
                      :id="`search-exclude-discount-${pricing_item_cloud.id}`"
                      class="input with-icon"
                      :placeholder="$t('helpers.search')"
                    />
                  </label>
                </div>
              </div>
            </template>
            <div class="grid grid-cols-3 gap-2">
              <span
                v-for="(item, index) in excludeDiscountsFilter"
                :key="index"
              >
                <label
                  :for="`exclude_item_${index}-${pricing_item_cloud.id}`"
                  class="custom-checkbox"
                >
                  <input
                    type="checkbox"
                    :id="`exclude_item_${index}-${pricing_item_cloud.id}`"
                    v-model="form.pricing_item_cloud.exclude_discount_ids"
                    :value="item.id"
                  />
                  {{ item.name }}
                  <span class="checkmark"></span>
                </label>
              </span>
            </div>
          </ConfirmModal>
        </dd>
      </dl>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted, computed } from "vue";
import { useForm } from "@inertiajs/vue3";

const props = defineProps({
  pricing_item_cloud: Object,
  index: Number,
  options_for_select: Object,
  exclude_discounts: Array,
});

const form = useForm({
  pricing_item_cloud: props.pricing_item_cloud,
});

const clientSupportOptions = ref(props.options_for_select.client_supports);
const addClientSupport = (value) => {
  clientSupportOptions.value.push(value);
  form.pricing_item_cloud.client_support = value;
};
const checkedClientSupportEmail = ref(
  !!props.pricing_item_cloud.client_support_way.email
);
const checkedClientSupportGroup = ref(
  !!props.pricing_item_cloud.client_support_way.group.sms
);

const searchExcludeDiscounts = ref("");
const excludeDiscountsFilter = computed(() => {
  return props.exclude_discounts.filter((item) =>
    item.name.includes(searchExcludeDiscounts.value)
  );
});

const selectedExcludeDiscountsCount = computed(() => {
  return form.pricing_item_cloud.exclude_discount_ids.length === 0
    ? ""
    : form.pricing_item_cloud.exclude_discount_ids.length ===
      props.exclude_discounts.length
    ? "(all)"
    : `(${form.pricing_item_cloud.exclude_discount_ids.length})`;
});

const emit = defineEmits(["submit"]);

onMounted(() => {
  window.HSStaticMethods.autoInit();
  watch(checkedClientSupportEmail, (newVal) => {
    if (!newVal) {
      form.pricing_item_cloud.client_support_way.email = null;
    }
  });
  watch(checkedClientSupportGroup, (newVal) => {
    if (!newVal) {
      form.pricing_item_cloud.client_support_way.group.sms = null;
      form.pricing_item_cloud.client_support_way.group.name = null;
    }
  });

  watch(form.pricing_item_cloud, () => {
    delete form.pricing_item_cloud.cloud_name;
    emit("submit", form.pricing_item_cloud);
  });
});
</script>

<template>
  <div id="cloud_account-content" role="tabpanel" aria-labelledby="cloud_account-tab" class="hidden">
    <div class="-mx-4 -mt-3 min-h-[300px]">
    <DataTable :resources="cloudAccountList">
      <template #actions>
        <ConfirmModal
          :button-text="$t('cloud_account.modal.new')"
          :modal-cancel-text="$t('helpers.back')"
          :modal-submit-text="$t('cloud_account.modal.submit')"
          modal-size="xl"
          modalSubmitClass="btn btn-sm btn-primary"
          @submit="addCloudAccount"
          class="btn btn-sm btn-primary"
        >
          <SelectCloudAccount
            :cloud_account_ids="form.pricing_item.cloud_account_ids"
            :all_cloud_accounts="all_cloud_accounts"
            @submit="addTempCloudAccount"
            :key="resetSelectCloudAccount"
          />
        </ConfirmModal>
      </template>
      <template #thead>
        <DataTableHead
          v-for="thead in theads"
          :id="thead.id"
          :text="$t(thead.i18n_key)"
          :defaultOrderColumn="thead.defaultOrderColumn"
          :disableOrder="thead.disableOrder"
        >
        </DataTableHead>
        <DataTableHead
          id="actions"
          :text="$t('helpers.actions')"
          :disableOrder="true"
        >
        </DataTableHead>
      </template>
      <template #tbody-tr="{ resource }">
        <td>{{ resource.account_id }}</td>
        <td>{{ resource.created_at || $t("helpers.not_added") }}</td>
        <td></td>
        <td></td>
        <td>
          <span
            class="underline cursor-pointer text-warning"
            @click="removeCloudAccount(resource.id)"
          >
            {{ $t("helpers.remove") }}
          </span>
        </td>
      </template>
    </DataTable>
  </div>

  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from "vue";
import { useForm } from "@inertiajs/vue3";
import SelectCloudAccount from "./_select_cloud_account.vue";

const theads = [
  {
    id: "account_id",
    i18n_key: "activerecord.attributes.cloud_account.account_id",
    defaultOrderColumn: true,
  },
  {
    id: "created_at",
    i18n_key: "client/pricing_item.cloud_account.created_at",
    disableOrder: true,
  },
  {
    id: "company",
    i18n_key: "supplier/pricing_item.cloud_account.company",
    disableOrder: true,
  },
  {
    id: "company/contract",
    i18n_key: "supplier/pricing_item.cloud_account.company/contract",
    disableOrder: true,
  },
];

const props = defineProps({
  pricing_item: Object,
  cloud_accounts: Array,
  all_cloud_accounts: Array,
});

const form = useForm({
  pricing_item: {
    cloud_account_ids: props.pricing_item.cloud_account_ids,
  },
});

const resetSelectCloudAccount = ref(0);
const cloudAccountList = ref([...props.cloud_accounts]);

const tempCloudAccountIds = ref([]);
const addTempCloudAccount = (cloud_account_ids) => {
  tempCloudAccountIds.value = cloud_account_ids;
};

const addCloudAccount = () => {
  const newCloudAccount = props.all_cloud_accounts.filter((cloud_account) => {
    return tempCloudAccountIds.value.includes(cloud_account.id);
  });
  cloudAccountList.value = [...newCloudAccount, ...cloudAccountList.value];
  cloudAccountList.value = cloudAccountList.value.filter(
    (cloud_account, index, self) => index === self.findIndex((t) => t.id === cloud_account.id)
  );
};

const removeCloudAccount = (cloud_account_id) => {
  cloudAccountList.value = cloudAccountList.value.filter((cloud_account) => {
    return cloud_account.id !== cloud_account_id;
  });
  tempCloudAccountIds.value = tempCloudAccountIds.value.filter((id) => {
    return id !== cloud_account_id;
  });
  resetSelectCloudAccount.value += 1;
};

const emit = defineEmits(["submit"]);

onMounted(() => {
  watch(cloudAccountList, () => {
    form.pricing_item.cloud_account_ids = cloudAccountList.value.map((cloud_account) => cloud_account.id);
    emit("submit", form.pricing_item);
  });
});
</script>

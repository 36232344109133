<template>
  <button
    type="button"
    class="px-3 py-1 text-sm rounded bg-primary-light hs-tab-active:bg-primary-dark"
    :class="{
      'active': index === 0,
      'hidden': pricing_item_cloud._destroy == 1
       }"
    :id="`pricing_item_cloud-tabs-item-${pricing_item_cloud.id}`"
    :aria-selected="index === 0"
    :data-hs-tab="`#pricing_item_cloud-tabs-${pricing_item_cloud.id}`"
    :aria-controls="`pricing_item_cloud-tabs-${pricing_item_cloud.id}`"
    role="tab"
  >
    <div class="flex items-center justify-between w-full gap-2">
      <div>
      <multiselect
        v-model="form.pricing_item_cloud.cloud_name"
        :options="cloud_name_options"
        :show-labels="false"
        :allow-empty="false"
        :max-height="120"
        class="multiselect-sm flex-1 !min-w-full"
        :placeholder="$t('helpers.select')"
      >
        <template #singleLabel="props">
            {{ $t(`client/pricing_item_cloud.cloud_name.${props.option}`) }}
          </template>
          <template #option="props">
            {{ $t(`client/pricing_item_cloud.cloud_name.${props.option}`) }}
          </template>
        <template #caret>
          <IconSvg
            icon="arrow_down"
            class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
          ></IconSvg>
        </template>
      </multiselect>
      </div>
      <button class="inline-flex items-center justify-center" @click.stop="removeCloud(form.pricing_item_cloud.id)" type="button">
        <IconSvg icon="delete" class="size-5 fill-white"></IconSvg>
      </button>
    </div>
  </button>
</template>
<script setup>
import { ref, watch, onMounted, computed } from "vue";
import { useForm } from "@inertiajs/vue3";

const props = defineProps({
  pricing_item_cloud: Object,
  index: Number,
  cloud_name_options: Array,
});

const form = useForm({
  pricing_item_cloud: props.pricing_item_cloud,
});

const emit = defineEmits(["submit", "remove"]);

const removeCloud = () => {
  form.pricing_item_cloud._destroy = 1
  emit('remove', form.pricing_item_cloud.id)
};

onMounted(() => {
  window.HSStaticMethods.autoInit();
  watch(form.pricing_item_cloud, () => {
    emit("submit", {
      id: form.pricing_item_cloud.id,
      cloud_name: form.pricing_item_cloud.cloud_name,
    });
  });
});
</script>

<template>
  <div id="info-content" role="tabpanel" aria-labelledby="info-tab">
    <div class="space-5">
      <dl class="grid grid-cols-10 gap-5 my-5">
        <dt class="flex items-center col-span-1">
          <label for="pricing_item_name" class="ml-3 required">
            {{ $t("activerecord.attributes.client/pricing_item.name") }}
          </label>
        </dt>
        <dd class="col-span-4">
          <input
            v-model="form.pricing_item.name"
            type="text"
            class="flex-1 input"
            id="pricing_item_name"
          />
        </dd>
      </dl>
    </div>
    <div class="flex gap-2" :key="remountKey">
      <nav
        class="flex gap-x-2"
        aria-label="Tabs"
        role="tablist"
        aria-orientation="horizontal"
        v-if="visibleClouds.length > 0"
      >
        <SelectCloud
          v-for="(pricing_item_cloud, index) in visibleClouds"
          :key="pricing_item_cloud.id"
          :pricing_item_cloud="pricing_item_cloud"
          :index="index"
          :cloud_name_options="selectableCloudNames"
          @submit="modifyPricingItemCloudName"
          @remove="removeCloud"
        />
      </nav>
      <button
        class="btn btn-sm btn-outline btn-primary"
        type="button"
        @click="addCloud"
        :class="{ hidden: !canAddCloud }"
      >
        <IconSvg icon="new" class="w-4 h-4"></IconSvg>
        {{ $t("client/pricing_item_cloud.form.add_cloud") }}
      </button>
    </div>
    <ContentCloud
      v-for="(pricing_item_cloud, index) in visibleClouds"
      :key="pricing_item_cloud.id"
      :pricing_item_cloud="pricing_item_cloud"
      :index="index"
      :options_for_select="options_for_select"
      :exclude_discounts="exclude_discounts"
      @submit="modifyPricingItemCloud"
    />

    <div
      v-if="visibleClouds.length === 0"
      class="flex items-center justify-center w-full px-5 py-3 my-5 -mx-5 border-t border-b h-60 border-gray-20 bg-gray-5"
    >
      <p class="text-gray-40">
        {{ $t("client/pricing_item.form.add_cloud_desc") }}
      </p>
    </div>

    <div v-else>
      <TabNav :tab-list="sub_menu_lists" class="py-0 py-5">
        <div
          id="special_discount-content"
          role="tabpanel"
          aria-labelledby="special_discount-tab"
        >
          <SpecialDiscountList
            :new_special_discount="props.new_special_discount"
            :special_discounts="props.special_discounts"
            :options_for_select="specialDiscountOptionsForSelect"
            @submit="modifyPricingItem"
          />
        </div>
      </TabNav>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed, nextTick } from "vue";
import { useForm } from "@inertiajs/vue3";
import SelectCloud from "./_select_cloud.vue";
import ContentCloud from "./_content_cloud.vue";
import SpecialDiscountList from "@views/special_discounts/_list.vue";

const props = defineProps({
  contract: Object,
  pricing_item: Object,
  exclude_discounts: Array,
  options_for_select: Object,
  new_pricing_item_cloud: Object,
  new_special_discount: Object,
  pricing_item_clouds: {
    type: Array,
    default: [],
  },
  special_discounts: {
    type: Array,
    default: [],
  },
});

const pricing_item_clouds = ref(props.pricing_item_clouds);

const sub_menu_lists = [
  {
    id: "special_discount",
    i18n_key: "activerecord.models.special_discount",
    enable: true,
  },
];

const form = useForm({
  pricing_item: {
    ...props.pricing_item,
    pricing_item_clouds_attributes: pricing_item_clouds.value,
    special_discounts_attributes: props.special_discounts,
  },
});

const visibleClouds = computed(() => {
  return form.pricing_item.pricing_item_clouds_attributes.filter(
    (cloud) => cloud._destroy != 1
  );
});

const selectableCloudNames = computed(() => {
  return props.options_for_select.cloud_names.filter(
    (item) =>
      !form.pricing_item.pricing_item_clouds_attributes.some(
        (cloud) => cloud.cloud_name === item
      )
  );
});

const specialDiscountOptionsForSelect = computed(() => {
  const {
    special_discount_targets,
    special_discount_categories,
    special_discount_operators,
  } = props.options_for_select;

  return {
    special_discount_targets,
    special_discount_categories,
    special_discount_operators,
  };
});

const canAddCloud = computed(() => {
  return (
    form.pricing_item.pricing_item_clouds_attributes.filter(
      (cloud) => cloud._destroy != 1
    ).length < 1 || props.contract.product_scope === "multi_cloud"
  );
});

const remountKey = ref(0);

const addCloud = () => {
  form.pricing_item.pricing_item_clouds_attributes.push({
    ...props.new_pricing_item_cloud,
    id:
      `temp_${Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)}`,
  });
  remountKey.value++;
  nextTick(() => {
    window.HSStaticMethods.autoInit();
  });
};

const removeCloud = (id) => {
  form.pricing_item.pricing_item_clouds_attributes.find(
    (cloud) => cloud.id === id
  )._destroy = 1;
  modifyPricingItem(form.pricing_item);
};

const modifyPricingItemCloudName = (new_value) => {
  const index = form.pricing_item.pricing_item_clouds_attributes.findIndex(
    (cloud) => cloud.id === new_value.id
  );

  form.pricing_item.pricing_item_clouds_attributes[index] = {
    ...form.pricing_item.pricing_item_clouds_attributes[index],
    cloud_name: new_value.cloud_name,
  };

  modifyPricingItem(form.pricing_item);
};

const modifyPricingItemCloud = (new_value) => {
  const index = form.pricing_item.pricing_item_clouds_attributes.findIndex(
    (cloud) => cloud.id === new_value.id
  );

  form.pricing_item.pricing_item_clouds_attributes[index] = {
    ...form.pricing_item.pricing_item_clouds_attributes[index],
    ...new_value,
  };

  modifyPricingItem(form.pricing_item);
};

const modifyPricingItem = (new_value) => {
  Object.assign(form.pricing_item, new_value);
};

const emit = defineEmits(["submit"]);

onMounted(() => {
  watch(form.pricing_item, () => {
    emit("submit", form.pricing_item);
  });
});
</script>

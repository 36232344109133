<template>
  <div class="space-5">
    <dl class="grid grid-cols-10 gap-5 my-5">
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="client_contract_status">{{
          $t("activerecord.attributes.client/contract.status")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-9 gap-5 w-max">
        <div>
          <multiselect
            v-model="form.contract.status"
            :options="props.options_for_select.statuses"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
          >
            <template #singleLabel="props">
              {{ $t(`client/contract.status.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`client/contract.status.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="client_contract_subsidiary">{{
          $t("activerecord.attributes.client/contract.subsidiary")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-2 gap-5">
        <label
          v-for="subsidiary in props.options_for_select.subsidiaries"
          :for="`subsidiary_${subsidiary}`"
          class="custom-radio"
        >
          <input
            type="radio"
            :id="`subsidiary_${subsidiary}`"
            v-model="form.contract.subsidiary"
            :value="subsidiary"
          />
          {{ $t(`client/contract.subsidiary.${subsidiary}`) }}
          <span class="checkmark"></span>
        </label>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="client_contract_user">{{
          $t("activerecord.attributes.client/contract.user")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-6 gap-5 w-max">
        <multiselect
          v-model="selectedUser"
          :options="props.options_for_select.users"
          :show-labels="false"
          :allow-empty="false"
          :max-height="120"
          class="!min-w-full"
          id="client_contract_user"
          :placeholder="$t('helpers.select')"
          @select="form.contract.user_id = $event.id"
        >
          <template #singleLabel="props">
            {{ `${props.option.email} (${props.option.name})` }}
          </template>
          <template #option="props">
            {{ `${props.option.email} (${props.option.name})` }}
          </template>
          <template #caret>
            <IconSvg
              icon="arrow_down"
              class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
            ></IconSvg>
          </template>
        </multiselect>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="client_contract_serial_no">{{
          $t("activerecord.attributes.client/contract.serial_no")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <input
          type="text"
          class="input"
          id="client_contract_serial_no"
          v-model="form.contract.serial_no"
          :class="{
            error: !form.contract.serial_no,
          }"
        />
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="client_contract_name">{{
          $t("activerecord.attributes.client/contract.name")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-6 gap-5 w-max">
        <input
          type="text"
          class="input"
          id="client_contract_name"
          v-model="form.contract.name"
          :class="{
            error: !form.contract.name,
          }"
        />
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="client_contract_start_at">{{
          $t("activerecord.attributes.client/contract.start_at")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-9 gap-3">
        <DatePicker
          :current_date="form.contract.start_at"
          @submit="modifyStartAt"
        />
        <div class="flex items-center gap-2">
          <label class="ml-3 required" for="client_contract_end_at">{{
            $t("activerecord.attributes.client/contract.end_at")
          }}</label>

          <DatePicker
            :current_date="form.contract.end_at"
            @submit="modifyEndAt"
            :disabled="!form.contract.start_at"
          />
        </div>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="client_contract_product_scope">{{
          $t("activerecord.attributes.client/contract.product_scope")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <div>
          <multiselect
            v-model="form.contract.product_scope"
            :options="props.options_for_select.product_scopes"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
          >
            <template #singleLabel="props">
              {{ $t(`client/contract.product_scope.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`client/contract.product_scope.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="client_contract_trade_tier">{{
          $t("activerecord.attributes.client/contract.trade_tier")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-6 gap-5 w-max">
        <div>
          <multiselect
            v-model="form.contract.trade_tier"
            :options="props.options_for_select.trade_tiers"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
          >
            <template #singleLabel="props">
              {{ $t(`client/contract.trade_tier.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`client/contract.trade_tier.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="client_contract_contact">{{
          $t("activerecord.attributes.client/contract.contact")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-6 gap-5 w-max">
        <div class="flex items-center gap-1">
          <label for="client_contract_same_contact" class="custom-checkbox">
            <input
              type="checkbox"
              id="client_contract_same_contact"
              v-model="sameContact"
            />
            {{ $t("supplier/contract.contact.same_as_product") }}
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="flex items-center gap-1">
          <label class="ml-3 required" for="client_contract_contact_email">{{
            $t("activerecord.attributes.client/contract.contact_email")
          }}</label>

          <span>
            <input
              class="input"
              id="client_contract_contact_email"
              type="email"
              v-model="form.contract.contact.email"
              :disabled="sameContact"
            />
          </span>
        </div>

        <div class="flex items-center gap-1">
          <label class="ml-3 required" for="client_contract_contact_name">{{
            $t("activerecord.attributes.client/contract.contact_name")
          }}</label>

          <span>
            <input
              class="input"
              id="client_contract_contact_name"
              type="text"
              v-model="form.contract.contact.name"
              :disabled="sameContact"
            />
          </span>
        </div>
      </dd>
    </dl>
    <hr class="border-gray-40" />

    <dl class="grid grid-cols-10 gap-5 my-5">
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="client_contract_payment_term">{{
          $t("activerecord.attributes.client/contract.payment_term")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-9 gap-5 w-max">
        <div>
          <multiselect
            v-model="form.contract.payment_term"
            :options="props.options_for_select.payment_terms"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
          >
            <template #singleLabel="props">
              {{ $t(`client/contract.payment_term.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`client/contract.payment_term.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="client_contract_bill_format">{{
          $t("activerecord.attributes.client/contract.bill_format")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <multiselect
          v-model="form.contract.bill_format"
          :options="props.options_for_select.bill_formats"
          :show-labels="false"
          :allow-empty="false"
          :max-height="120"
          id="client_contract_bill_format"
          :placeholder="$t('helpers.select')"
        >
          <!-- <template #singleLabel="props">
            {{ $t(`client/contract.bill_format.${props.option}`) }}
          </template>
          <template #option="props">
            {{ $t(`client/contract.bill_format.${props.option}`) }}
          </template> -->
          <template #caret>
            <IconSvg
              icon="arrow_down"
              class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
            ></IconSvg>
          </template>
        </multiselect>
      </dd>
      <dt class="flex items-center col-span-7 gap-1">
        <label class="ml-3 required" for="client_contract_bill_consolidation">{{
          $t("activerecord.attributes.client/contract.bill_consolidation")
        }}</label>

        <div class="flex items-center gap-1">
          <multiselect
          v-model="form.contract.bill_consolidation"
          :options="props.options_for_select.bill_consolidations"
          :show-labels="false"
          :allow-empty="false"
          :max-height="120"
          id="client_contract_bill_consolidation"
          :placeholder="$t('helpers.select')"
        >
          <template #singleLabel="props">
            {{ $t(`client/contract.bill_consolidation.${props.option}`) }}
          </template>
          <template #option="props">
            {{ $t(`client/contract.bill_consolidation.${props.option}`) }}
          </template>
          <template #caret>
            <IconSvg
              icon="arrow_down"
              class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
            ></IconSvg>
          </template>
        </multiselect>
        </div>
      </dt>

      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="client_contract_monthly_min_amount_currency">{{
          $t("activerecord.attributes.client/contract.monthly_min_amount_currency")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <multiselect
          v-model="form.contract.monthly_min_amount_currency"
          :options="props.options_for_select.monthly_min_amount_currencies"
          :show-labels="false"
          :allow-empty="false"
          :max-height="120"
          id="client_contract_monthly_min_amount_currency"
          :placeholder="$t('helpers.select')"
        >
          <template #singleLabel="props">
            {{ $t(`client/contract.monthly_min_amount_currency.${props.option}`) }}
          </template>
          <template #option="props">
            {{ $t(`client/contract.monthly_min_amount_currency.${props.option}`) }}
          </template>
          <template #caret>
            <IconSvg
              icon="arrow_down"
              class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
            ></IconSvg>
          </template>
        </multiselect>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="client_contract_monthly_min_amount">{{
          $t("activerecord.attributes.client/contract.monthly_min_amount")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-6 gap-5 w-max">
        <input
          type="number"
          class="input"
          min="0"
          @wheel="$event.target.blur()"
          id="client_contract_monthly_min_amount"
          v-model="form.contract.monthly_min_amount"
          :class="{
            error: !form.contract.monthly_min_amount,
          }"
        />
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="client_contract_comment">{{
          $t("activerecord.attributes.client/contract.comment")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between w-full col-span-7 gap-5">
        <input
          class="input"
          id="client_contract_comment"
          v-model="form.contract.comment"
        />
      </dd>
    </dl>
  </div>
</template>
<script setup>
import { useForm, router } from "@inertiajs/vue3";
import { ref, watch, onMounted } from "vue";

const props = defineProps({
  contract: {
    type: Object,
    required: true,
  },
  organization: {
    type: Object,
    required: true,
  },
  options_for_select: Object,
});
const form = useForm({
  contract: props.contract
});

const emit = defineEmits(["submit"]);
const selectedUser = ref(
  props.options_for_select.users.find(
    (user) => user.id === props.contract.user_id
  )
);

const sameContact = ref(!props.contract.id);

const modifyStartAt = (value) => {
  form.contract.start_at = value;
};

const modifyEndAt = (value) => {
  form.contract.end_at = value;
};

onMounted(() => {
  watch(sameContact, () => {
    if (sameContact.value) {
      form.contract.contact.email = props.organization.primary_contact.email;
      form.contract.contact.name = props.organization.primary_contact.name;
    }
  });
  watch(form.contract, () => {
    emit("submit", form.contract);
  });
});
</script>

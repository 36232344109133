<template>
  <form @submit.prevent="formSubmit">
    <div class="card">
      <div class="card-body">
        <TabNav :tab-list="sub_menu_lists" class="py-0">
          <template #sub-content>
            <div class="flex items-center gap-2">
            <h5 class="font-bold">
              {{ $t("activerecord.attributes.product.name") }}：{{
                product.name
              }}
            </h5>
            <IconSvg icon="arrow_right" class="w-4 h-4 fill-black"></IconSvg>
            <h5 class="font-bold">
              {{ $t("activerecord.attributes.supplier/contract.name") }}：{{
                contract.name
              }}
            </h5>
            </div>
          </template>

          <TabInfo
            :pricing_item="form.pricing_item"
            :exclude_discounts="exclude_discounts"
            :options_for_select="options_for_select"
            :new_special_discount="new_special_discount"
            :special_discounts="special_discounts"
            @submit="modifyPricingItem"
          />
          <TabCloudAccount
            :product="product"
            :pricing_item="form.pricing_item"
            :cloud_accounts="cloud_accounts"
            :all_cloud_accounts="all_cloud_accounts"
            @submit="modifyPricingItem"
          />
        </TabNav>
      </div>

      <div class="card-footer">
        <FormAction
          :can-submit="validate(form.pricing_item)"
          :delete-url="`/products/${product.id}/contracts/${contract.id}/pricing_items/${pricing_item.id}`"
          :delete-target="pricing_item.name"
          :delete-from="$t('supplier/contract.sub_nav.pricing_item')"
          :back-url="`/products/${product.id}/contracts/${contract.id}/pricing_items`"
          :has-modified="form.isDirty"
          :submit-text="$t('helpers.save')"
          :processing="form.processing"
        />
      </div>
    </div>
  </form>
</template>
<script setup>
import { useForm } from "@inertiajs/vue3";
import FormAction from "@/components/form_action.vue";
import TabInfo from "./_tab_info.vue";
import TabCloudAccount from "./_tab_cloud_account.vue";
import { validate } from "./validate";

const props = defineProps({
  product: Object,
  contract: Object,
  pricing_item: Object,
  exclude_discounts: Array,
  new_special_discount: Object,
  special_discounts: Array,
  options_for_select: Object,
  cloud_accounts: Array,
  all_cloud_accounts: Array,
  sub_menu_lists: Array,
});

const form = useForm({
  pricing_item: props.pricing_item,
});

const modifyPricingItem = (new_value) => {
  Object.assign(form.pricing_item, new_value);
};

const formSubmit = () => {
  delete form.pricing_item.id;
  form.put(
    `/products/${props.product.id}/contracts/${props.contract.id}/pricing_items/${props.pricing_item.id}`
  );
};
</script>

const validateSupportWay = (way) => {
  return !!way.email || (!!way.group && !!way.group.sms && !!way.group.name);
};

const validate = (pricing_item) => {
  return (
    !!pricing_item.name
  );
};

export { validate };

<template>
  <div class="flex items-center justify-between">
    <ConfirmModal
      :modal-cancel-text="$t('helpers.cancel')"
      :modal-title="$t('activerecord.models.special_discount')"
      modalSubmitClass="btn btn-sm btn-primary"
      :modal-submit-text="$t('helpers.confirm')"
      modalSize="xl"
      @submit="newSpecialDiscount"
      class="btn btn-sm btn-primary"
      :prevent-submit="!validate(tempSpecialDiscount)"
    >
      <template #button>
        <IconSvg icon="new" class="w-4 h-4"></IconSvg>
        {{
          $t("helpers.new", {
            model: $t("activerecord.models.special_discount"),
          })
        }}
      </template>

      <template #default>
        <SpecialDiscountForm
          :special_discount="new_special_discount"
          :options_for_select="options_for_select"
          @submit="addTempSpecialDiscount"
          :key="newKey"
        />
      </template>
    </ConfirmModal>

    <div class="relative">
      <label for="search-special-discount" class="relative block">
        <IconSvg
          icon="search"
          class="w-4 h-4 fill-gray-80 with-input"
        ></IconSvg>

        <input
          v-model="searchSpecialDiscount"
          type="text"
          id="search-special-discount"
          class="input with-icon"
          :placeholder="$t('helpers.search')"
        />
      </label>
    </div>
  </div>
  <div
    class="flex flex-col gap-5 px-5 py-3 my-5 -mx-5 border-t border-b border-gray-20 bg-gray-5"
  >
    <div v-if="specialDiscounts.length === 0" class="text-left text-gray-40">
      {{ $t("helpers.no_data") }}
    </div>
    <Item
      v-for="special_discount in specialDiscounts"
      :special_discount="special_discount"
      :options_for_select="options_for_select"
      :key="special_discount.id || `temp_${Math.random().toString(36).substring(2, 15)}`"
      :search="searchSpecialDiscount"
      @submit="editSpecialDiscount"
      @remove="removeSpecialDiscount"
    ></Item>
  </div>
</template>
<script setup>
import { ref, computed, onMounted} from "vue";
import Item from "./_item.vue";
import SpecialDiscountForm from "./_form.vue";
import { validate } from "./validate";

const props = defineProps({
  new_special_discount: Object,
  options_for_select: Object,
  special_discounts: {
    type: Array,
    default: [],
  },
});

const specialDiscounts = ref(props.special_discounts);

const searchSpecialDiscount = ref('');

const tempSpecialDiscount = ref({});
const addTempSpecialDiscount = (special_discount) => {
  tempSpecialDiscount.value = special_discount;
};

const emit = defineEmits(["submit"]);
const newKey = ref(0);
const newSpecialDiscount = () => {
  specialDiscounts.value.push(tempSpecialDiscount.value);
  newKey.value++;
  emit("submit", {
    special_discounts_attributes: specialDiscounts,
  });
};

const editSpecialDiscount = (special_discount) => {
  const index = specialDiscounts.value.findIndex(
    ({ id }) => id === special_discount.id
  );

  specialDiscounts.value[index] = special_discount;

  emit("submit", {
    special_discounts_attributes: specialDiscounts,
  });
};

const removeSpecialDiscount = (special_discount_id) => {
  specialDiscounts.value.find(
    ({ id }) => id === special_discount_id
  )._destroy = 1;

  emit("submit", {
    special_discounts_attributes: specialDiscounts.value,
  });
};

onMounted(() => {
  window.HSStaticMethods.autoInit()
});
</script>

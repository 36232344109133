<template>
  <div class="card">
    <div class="card-body">
      <SubNav :sub-nav-list="index_menu_lists"></SubNav>
    </div>
    <DataTable :resources="cloud_accounts" :paginate="paginate">
      <template #actions>
        <div class="flex items-center gap-5">
          <ListSetting
            :list_setting="props.list_setting"
            :updateEndpoint="`/organizations/${organization.id}/cloud_accounts/list_setting`"
          />
        </div>
      </template>
      <template #thead>
        <DataTableHead
          v-for="thead in props.list_setting"
          :id="thead"
          :text="$t(`cloud_account.index.${thead}`)"
          :defaultOrderColumn="thead === 'account_id'"
          :disableOrder="thead != 'account_id'"
        >
        </DataTableHead>
      </template>
      <template #tbody-tr="{ resource }">
        <td v-for="cloud_account_column in list_setting">
          {{ resource[cloud_account_column] }}
        </td>
      </template>
    </DataTable>
  </div>
</template>
<script setup>
import ListSetting from "@/views/cloud_accounts/_list_setting.vue";
const props = defineProps({
  organization: Object,
  cloud_accounts: Array,
  paginate: Object,
  list_setting: Object,
  index_menu_lists: Array,
});
</script>

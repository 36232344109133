<template>
  <div class="pb-0 card">
    <div class="card-body">
      <div class="flex items-center justify-between">
        <SubNav :sub-nav-list="index_menu_lists"></SubNav>
        <h5 class="font-bold">
          {{ $t("activerecord.attributes.product.name") }}：{{ product.name }}
        </h5>
      </div>

      <div class="flex items-center justify-between">
        <Link
          :href="`/products/${product.id}/contracts/${contract.id}/pricing_items/new`"
          class="btn btn-sm btn-primary"
        >
          <IconSvg icon="new" class="w-4 h-4"></IconSvg>
          {{ $t("helpers.new", { model: $t("activerecord.models.supplier/pricing_item") }) }}
        </Link>

        <div class="relative">
          <label for="search-pricing-item" class="relative block">
            <IconSvg
              icon="search"
              class="w-4 h-4 fill-gray-80 with-input"
            ></IconSvg>

            <input
              v-model="searchPricingItem"
              type="text"
              id="search-pricing-item"
              class="input with-icon"
              :placeholder="$t('helpers.search')"
            />
          </label>
        </div>
      </div>

      <div
        class="flex flex-col gap-5 px-5 py-3 my-5 -mx-5 border-t border-b border-gray-20 bg-gray-5"
      >
        <template v-if="props.pricing_items.length === 0">
          <div class="flex items-center justify-center w-full h-60">
            <p class="text-gray-40">{{ $t("supplier/pricing_item.form.add_pricing_item_desc") }}</p>
          </div>
        </template>
        <Item
          v-for="pricing_item in pricingItemsFilter"
          :key="pricing_item.id"
          :pricing_item="pricing_item"
          :product_id="product.id"
          :contract_id="contract.id"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import Item from "./_item.vue";

const props = defineProps({
  product: Object,
  contract: Object,
  pricing_items: Array,
  paginate: Object,
  index_menu_lists: Array
});

const searchPricingItem = ref('');
const pricingItemsFilter = computed(() => {
  return props.pricing_items.filter(item => item.name.includes(searchPricingItem.value))
})
</script>

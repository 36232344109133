<template>
  <div class="flex flex-col gap-5 py-3">
    <div class="flex items-center gap-5">
      <label
        for="pricing_item_structure_config_target_item"
        class="ml-3 required"
      >
        {{ $t("special_discount.structure_config.target_item") }}
      </label>

      <input
        v-model="form.structure_config.target_item"
        type="text"
        class="flex-1 input !max-w-[fit-content]"
        id="pricing_item_structure_config_target_item"
      />
    </div>
    <div class="flex items-start gap-3 px-3 overflow-x-scroll step_config">
      <div class="flex flex-col items-center gap-3 initial_step">
        <div class="step-value-wrapper">0</div>
        <div class="step-node"></div>
        <div class="flex items-center gap-1 py-2 text-sm text-black">
          {{
            $t(
              `special_discount.structure_config.${props.category}`
            )
          }}
        </div>
      </div>

      <Step
        v-for="(step, index) in middleSteps"
        :key="step.id"
        :step="step"
        :index="index"
        :category="props.category"
        @removeStep="removeStep"
        @submit="modifyStep"
      />

      <div class="flex flex-col items-center gap-3 final_step">
        <button
          as="button"
          class="rounded-br-none btn btn-sm btn-primary new_step"
          @click="addStep"
        >
          <IconSvg icon="new" class="size-4"></IconSvg>
          {{
            $t("helpers.new", {
              model: $t("special_discount.structure_config.steps"),
            })
          }}
        </button>
        <div class="step-node"></div>
        <div
          class="flex items-center gap-1 step_discount"
          v-if="props.category === 'unit_price'"
        >
          <input
            type="number"
            min="0"
            v-model="finalStepDiscount"
            @wheel="$event.target.blur()"
            placeholder="0.000000"
            class="input !max-w-[100px]"
            :class="{
              error: !finalStepDiscount,
            }"
          />
        </div>
        <div
          class="flex items-center gap-1 step_discount"
          v-if="props.category === 'percentage'"
        >
          -
          <input
            v-model="finalStepDiscount"
            type="number"
            min="0"
            @wheel="$event.target.blur()"
            class="input !max-w-[100px]"
            placeholder="0.00"
            :class="{
              error: !finalStepDiscount,
            }"
          />
          %
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from "vue";
import { useForm } from "@inertiajs/vue3";
import Step from "./_step.vue";
const props = defineProps({
  category: String,
  structure_config: Object,
});

const form = useForm({
  structure_config: {
    ...props.structure_config,
    steps: props.structure_config.steps.map(step => ({
      ...step,
      // add a random id to make step render properly
      id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
    })),
  },
});

const newStepConfig = ref({
  step_value: null,
  operator: 'less_than',
  discount: null,
});

const middleSteps = ref(form.structure_config.steps.filter(step => step.step_value !== 'infinity'));
const finalStep = form.structure_config.steps.find(step => step.step_value === 'infinity');
const finalStepDiscount = ref(finalStep?.discount || null);

const emit = defineEmits(['submit']);

const submitFinalStep = (middleSteps, finalStepDiscountValue) => {
  const withFinalStep = {
    target_item: form.structure_config.target_item,
    steps: [...middleSteps.map(step => {
      delete step.id;
      return step;
    }), {
      ...newStepConfig.value,
      step_value: 'infinity',
      discount: finalStepDiscountValue,
    }],
  };

  emit('submit', withFinalStep);
}

const modifyStep = (step, index) => {
  middleSteps.value[index] = step;

  submitFinalStep(middleSteps.value, finalStepDiscount.value)
};

const addStep = () => {
  middleSteps.value.push(newStepConfig.value);
};

const removeStep = (index) => {
  middleSteps.value.splice(index, 1);
  submitFinalStep(middleSteps.value, finalStepDiscount.value)
};

onMounted(() => {
  watch(finalStepDiscount, (value) => {
    submitFinalStep(middleSteps.value, value)
  });
  watch(form.structure_config, (value) => {
    submitFinalStep(middleSteps.value, finalStepDiscount.value)
  });
});

</script>

<template>
  <form @submit.prevent="formSubmit">
    <div class="card">
      <div class="card-body">
        <TabNav :tab-list="props.sub_menu_lists" class="py-0">
          <TabInfo
            @submit="modifyCloudAccount"
            :cloud_account="form.cloud_account"
            :options_for_select="props.options_for_select"
          />

          <TabConnectWay
            @submit="modifyCloudAccount"
            :cloud_account_connector="
              form.cloud_account.cloud_account_connector_attributes
            "
          />

          <TabPricingItemHistory
            :supplier_pricing_item_histories="props.supplier_pricing_item_histories"
            :client_pricing_item_histories="props.client_pricing_item_histories"
          />

          <TabComment
            :cloud_account_id="props.cloud_account.id"
            :comments="props.comments"
          />
        </TabNav>
      </div>
      <div class="card-footer">
        <FormAction
          :can-delete="false"
          :can-submit="validate(form.cloud_account)"
          :back-url="'/cloud_accounts'"
          :has-modified="form.isDirty"
          :submit-text="$t('helpers.save')"
          :processing="form.processing"
        />
      </div>
    </div>
  </form>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import FormAction from "@/components/form_action.vue";
import TabInfo from "./_tab_info.vue";
import TabConnectWay from "./_tab_connect_way.vue";
import TabPricingItemHistory from "./_tab_pricing_item_history.vue";
import TabComment from "./_tab_comment.vue";
import { validate } from "./validate";

const props = defineProps({
  cloud_account: Object,
  cloud_account_connector: Object,
  supplier_pricing_item_histories: Array,
  client_pricing_item_histories: Array,
  comments: Array,
  options_for_select: Object,
  errors: Object,
  sub_menu_lists: Object,
});

const form = useForm({
  cloud_account: {
    ...props.cloud_account,
    cloud_account_connector_attributes: {
      id: props.cloud_account_connector.id,
      connect_way: props.cloud_account_connector.connect_way,
      credentials: props.cloud_account_connector.credentials,
    },
  },
});

const formSubmit = () => {
  delete form.cloud_account.id;
  form.put(`/cloud_accounts/${props.cloud_account.id}`);
};

const modifyCloudAccount = (new_value) => {
  Object.assign(form.cloud_account, new_value);
};
</script>

<template>
  <ConfirmModal
    :modal-cancel-text="$t('helpers.cancel')"
    :modal-title="$t('activerecord.models.special_discount')"
    modalSubmitClass="btn btn-sm btn-primary"
    :modal-submit-text="$t('helpers.confirm')"
    modalSize="xl"
    @submit="editSpecialDiscount(form.special_discount.id)"
    class="grid block w-full grid-cols-12 gap-2 px-5 py-4 bg-white rounded shadow-lg"
    :class="{
      hidden: form.special_discount._destroy == 1 || !form.special_discount.name.includes(props.search),
    }"
    :key="instance.uid"
    :prevent-submit="!validate(tempEditedSpecialDiscount)"
  >
    <template #button>
      <div class="self-center col-span-1 font-bold text-gray-700">
        {{ $t("activerecord.attributes.special_discount.name") }}
      </div>
      <div class="self-center col-span-4 text-gray-500">
        {{ form.special_discount.name }}
      </div>
      <div class="self-center col-span-1 font-bold text-gray-700">
        {{ $t("activerecord.attributes.special_discount.conditions") }}
      </div>
      <div class="self-center col-span-2 text-gray-500 truncate">
        <template v-if="form.special_discount.conditions.length > 1">
          {{
            $t(
              "supplier/pricing_item.special_discount.form.multiple_conditions"
            )
          }}({{ form.special_discount.conditions.length }})
        </template>
        <template v-if="form.special_discount.conditions.length === 1">
          {{ form.special_discount.conditions[0].target }}
          {{
            $t(
              `special_discount.conditions.${form.special_discount.conditions[0].operator}`
            )
          }}
          {{ form.special_discount.conditions[0].content }}
        </template>
      </div>
      <div class="self-center col-span-1 font-bold text-gray-700">
        {{
          $t(
            "activerecord.attributes.special_discount.structure_config"
          )
        }}
      </div>
      <div class="self-center col-span-2 text-gray-500">
        {{ $t(`special_discount.category.${form.special_discount.category}`) }}
        {{ $t(`special_discount.structure.${form.special_discount.structure}`) }}
      </div>
      <div class="flex justify-end col-span-auto">
        <button @click.stop="removeSpecialDiscount(form.special_discount.id)" type="button">
          <IconSvg icon="delete" class="size-6 fill-red-500"></IconSvg>
        </button>
      </div>
    </template>

    <template #default>
      <SpecialDiscountForm
        :special_discount="form.special_discount"
        :options_for_select="options_for_select"
        @submit="changeTempEditedSpecialDiscount"
      />
    </template>
  </ConfirmModal>
</template>
<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { useForm } from "@inertiajs/vue3";
import SpecialDiscountForm from "./_form.vue";
import { validate } from "./validate";

const props = defineProps({
  special_discount: Object,
  options_for_select: Object,
  search: String,
});

const instance = getCurrentInstance();
const form = useForm({
  special_discount: props.special_discount,
})

const emit = defineEmits(['submit', 'remove'])

const tempEditedSpecialDiscount = ref(props.special_discount);
const changeTempEditedSpecialDiscount = (special_discount) => {
  tempEditedSpecialDiscount.value = special_discount;
};

const editSpecialDiscount = () => {
  form.special_discount = tempEditedSpecialDiscount.value
  emit('submit', form.special_discount)
};

const removeSpecialDiscount = () => {
  form.special_discount._destroy = 1
  emit('remove', form.special_discount.id)
};

onMounted(() => {
  window.HSStaticMethods.autoInit();
});
</script>

<template>
  <tr :class="{ 'bg-gray-5': index % 2 === 0 }">
    <td>
      {{ contract.name }}
    </td>
    <td :class="{
      'text-warning': contract.status === 'disabled'
      }">
      {{ $t(`supplier/contract.status.${contract.status}`) }}
    </td>
    <td class="flex items-center gap-1">
      <span
        class="cursor-pointer text-gray-60"
        @click="toggleContract(contract.id)"
      >
        {{ contract.pricing_items_count }}

        <IconSvg
          icon="arrow_down"
          class="w-3 h-3 fill-gray-60"
          :class="{
            'rotate-180': openedContractId.includes(contract.id),
          }"
        ></IconSvg>
      </span>
    </td>
    <td>{{ contract.cloud_accounts_count }}</td>
    <td>{{ contract.comment }}</td>
    <td class="flex gap-2">
      <Link
        class="underline text-primary"
        :href="`/products/${product_id}/contracts/${contract.id}/edit`"
        >{{ $t("helpers.edit", { model: "" }) }}</Link
      >

      <ConfirmModal
        class="p-0 underline text-primary"
        :button-text="$t('supplier/contract.index.copy')"
        :modal-title="$t('supplier/contract.index.copy_modal.title')"
        :modal-cancel-text="$t('helpers.back')"
        :modal-submit-text="$t('helpers.confirm')"
        modal-submit-class="btn btn-sm btn-primary"
        @submit="submitCopy"
      >
        <div class="flex flex-col gap-5">
          <label :for="`copy_info_${contract.id}`" class="custom-checkbox">
            <input
              type="checkbox"
              :id="`copy_info_${contract.id}`"
              v-model="formCopy.contract.copy_info"
              value="true"
            />
            {{ $t("supplier/contract.index.copy_modal.info") }}
            <span class="checkmark"></span>
          </label>

          <label
            :for="`copy_business_info_${contract.id}`"
            class="custom-checkbox"
          >
            <input
              type="checkbox"
              :id="`copy_business_info_${contract.id}`"
              v-model="formCopy.contract.copy_business_info"
              value="true"
            />
            {{ $t("supplier/contract.index.copy_modal.business_info") }}
            <span class="checkmark"></span>
          </label>

          <div class="flex items-center gap-3">
            <IconSvg icon="arrow_right" class="w-5 h-5 fill-black"></IconSvg>
            <p>
              {{
                $t("supplier/contract.index.copy_modal.transfer_cloud_account")
              }}
            </p>

            <label
              :for="`transfer_cloud_account_${contract.id}_true`"
              class="custom-radio"
            >
              <input
                type="radio"
                :id="`transfer_cloud_account_${contract.id}_true`"
                v-model="formCopy.contract.transfer_cloud_account"
                value="true"
              />
              {{ $t("helpers.true") }}
              <span class="checkmark"></span>
            </label>
            <label
              :for="`transfer_cloud_account_${contract.id}_false`"
              class="custom-radio"
            >
              <input
                type="radio"
                :id="`transfer_cloud_account_${contract.id}_false`"
                v-model="formCopy.contract.transfer_cloud_account"
                value="false"
              />
              {{ $t("helpers.false") }}
              <span class="checkmark"></span>
            </label>
          </div>
          <p v-if="transferable">
            {{ $t("supplier/contract.index.copy_modal.copy_message", { name: contract.name, expired_at: copyExpiredAt }) }}
          </p>
        </div>
      </ConfirmModal>
    </td>
  </tr>
  <tr
    v-show="openedContractId.includes(contract.id)"
    :class="{ 'bg-gray-5': index % 2 === 0 }"
  >
    <td class="w-[200px]">
      <div class="flex flex-col gap-2 border-r border-gray-20">
        <h5 class="font-bold text-gray-60">
          {{ $t("activerecord.attributes.supplier/pricing_item.name") }}
        </h5>
        <h5 class="font-bold text-gray-60">
          {{
            $t(
              "activerecord.attributes.supplier/pricing_item.service_fee_percent"
            )
          }}%
        </h5>
        <h5 class="font-bold text-gray-60">
          {{
            $t(
              "activerecord.attributes.supplier/pricing_item.common_discount_percent"
            )
          }}
        </h5>
        <h5 class="h-8">&nbsp;</h5>
        <h5 class="font-bold text-gray-60">
          {{ $t("activerecord.models.special_discount") }}
        </h5>
      </div>
    </td>
    <td colspan="5" class="overflow-x-scroll">
      <div class="flex w-full gap-10">
        <div
          v-for="pricing_item in contract.pricing_items"
          :key="pricing_item.id"
          class="flex flex-col gap-2"
        >
          <p>{{ pricing_item.name }}</p>
          <p>{{ pricing_item.service_fee_percent }}%</p>
          <p>{{ pricing_item.common_discount_percent }}%</p>

          <CommonModal
            class="p-0 underline btn btn-sm btn-link"
            :button-text="$t('supplier/pricing_item.index.exclude_discount')"
            :modal-title="
              $t('supplier/pricing_item.form.exclude_discount.modal.title')
            "
          >
            <p
              v-for="exclude_discount in pricing_item.exclude_discounts"
              :key="exclude_discount.id"
            >
              {{ exclude_discount.name }}
            </p>
          </CommonModal>

          <p>
            <template v-if="pricing_item.special_discounts.length > 1">
              <div
                class="hs-tooltip [--trigger:hover] [--placement:bottom] inline-block"
              >
                <div class="flex items-center hs-tooltip-toggle">
                  <span class="underline text-gray-60"
                    >{{
                      $t(
                        "supplier/pricing_item.index.multiple_special_discounts"
                      )
                    }}
                    ({{ pricing_item.special_discounts.length }})</span
                  >
                  <div
                    class="absolute z-10 flex flex-col invisible inline-block gap-2 px-4 py-3 transition-opacity bg-white border rounded shadow opacity-0 hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible"
                    role="tooltip"
                  >
                    <p
                      class="text-sm text-left text-gray-60"
                      v-for="special_discount in pricing_item.special_discounts"
                      :key="special_discount.id"
                    >
                      {{ special_discount.name }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="pricing_item.special_discounts.length === 1">
              <span class="text-gray-60">{{
                pricing_item.special_discounts[0].name
              }}</span>
            </template>
            <span class="text-gray-60" v-else> - </span>
          </p>
        </div>
      </div>
    </td>
  </tr>
</template>

<script setup>
import { computed } from "vue";
import { useForm } from "@inertiajs/vue3";
const props = defineProps({
  product_id: String,
  contract: Object,
  index: Number,
  openedContractId: Array,
});

const formCopy = useForm({
  contract: {
    copy_info: true,
    copy_business_info: true,
    transfer_cloud_account: false,
  },
});

const transferable = computed(() => {
  return formCopy.contract.transfer_cloud_account === "true";
});

const emit = defineEmits(["toggleContract"]);
const toggleContract = (id) => {
  emit("toggleContract", id);
};

const submitCopy = () => {
  formCopy.post(`/products/${props.product_id}/contracts/${props.contract.id}/copy`);
};

const copyExpiredAt = computed(() => {
  const now = dayjs();
  const endOfMonth = now.endOf("month");
  const copyExpiredAt = now.isBefore(endOfMonth.set("date", 20)) ? endOfMonth : endOfMonth.add(1, "month").endOf("month");
  return copyExpiredAt.format("YYYY-MM-DD");
});

</script>

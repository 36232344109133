const validContractName = (contract, all_contract_names) => {
  return !!contract.name && !(all_contract_names).includes(contract.name);
};

const validStartAtEndAt = (contract) => {
  return !!contract.start_at && !!contract.end_at && contract.start_at <= contract.end_at;
};

const validate = (contract, all_contract_names) => {
  return (
    validContractName(contract, all_contract_names) &&
    validStartAtEndAt(contract) &&
    !!contract.status &&
    !!contract.user_id &&
    !!contract.subsidiary &&
    !!contract.product_scope &&
    !!contract.trade_tier &&
    !!contract.contact.email &&
    !!contract.contact.name &&
    !!contract.payment_term &&
    !!contract.bill_consolidation
  );
};

export { validate };

<template>
  <form @submit.prevent="formSubmit">
    <div class="card">
      <div class="card-body">
        <div class="flex items-center justify-between">
          <SubNav :sub-nav-list="sub_menu_lists"></SubNav>
          <h5 class="font-bold">
            {{ $t("activerecord.attributes.organization.name") }}：{{
              props.organization.name
            }}
          </h5>
        </div>
        <Info
          @submit="modifyContract"
          :contract="form.contract"
          :organization="props.organization"
          :options_for_select="props.options_for_select"
        />
      </div>

      <div class="card-footer">
        <FormAction
          :can-delete="false"
          :can-submit="validate(form.contract, all_contract_names)"
          :back-url="`/organizations/${organization.id}/contracts`"
          :has-modified="form.isDirty"
          :submit-text="$t('helpers.create')"
          :processing="form.processing"
        />
      </div>
    </div>
  </form>
</template>
<script setup>
import { useForm } from "@inertiajs/vue3";
import FormAction from "@/components/form_action.vue";
import Info from "./_info.vue";
import { validate } from "./validate";

const props = defineProps({
  organization: Object,
  options_for_select: Object,
  contract: Object,
  errors: Object,
  all_contract_names: Array,
  sub_menu_lists: Array,
});

const form = useForm({
  contract: props.contract,
});

const formSubmit = () => {
  form.post(`/organizations/${props.organization.id}/contracts`);
};

const modifyContract = (new_value) => {
  Object.assign(form.contract, new_value);
};
</script>

<template>
  <div
    class="bg-white shadow-lg rounded px-5 py-2 divide-y divide-gray-20 *:py-2"
  >
    <Link
      :href="`/products/${product_id}/contracts/${contract_id}/pricing_items/${pricing_item.id}/edit`"
      as="button"
      class="w-full"
    >
      <div class="grid grid-cols-3">
        <div class="flex items-center">
          <span
            v-t="'activerecord.attributes.supplier/pricing_item.name'"
            class="font-bold text-gray-60 me-2"
          ></span>
          <span class="text-sm text-gray-60">{{ pricing_item.name }}</span>
        </div>
        <div class="flex items-center">
          <span
            v-t="'activerecord.attributes.supplier/pricing_item.hook_account'"
            class="font-bold text-gray-60 me-2"
          ></span>
          <span class="text-sm text-gray-60">{{ pricing_item.hook_account }}</span>
        </div>

        <div class="flex items-center justify-end">
          <!-- destroy -->
          <ConfirmModal
            :modal-cancel-text="$t('delete_confirm.back')"
            :modal-submit-text="$t('helpers.delete')"
            :modal-title="$t('delete_confirm.title')"
            :url="deleteUrl"
            @submit="deleteAction"
            class="btn btn-sm"
          >
            <template #button>
              <IconSvg icon="delete" class="w-5 h-5 fill-black"></IconSvg>
            </template>
            <p>
              {{
                $t("delete_confirm.message", {
                  target: pricing_item.name,
                  from: $t('supplier/contract.sub_nav.pricing_item'),
                })
              }}
            </p>
          </ConfirmModal>
        </div>
      </div>

      <div class="grid grid-cols-12 divide-x divide-gray-20">
        <div class="col-span-10 space-y-4 pe-4">
          <div class="grid grid-cols-8 pb-2 border-b border-gray-20">
            <div class="flex items-center col-span-1">
              <span
                v-t="'activerecord.attributes.supplier/pricing_item.currency'"
                class="font-bold text-gray-60 me-2"
              ></span>
              <span
                class="text-sm text-gray-60"
                v-t="`supplier/pricing_item.currency.${pricing_item.currency}`"
              ></span>
            </div>
            <div class="flex items-center col-span-3">
              <span
                v-t="
                  'activerecord.attributes.supplier/pricing_item.exchange_rate'
                "
                class="font-bold text-gray-60 me-2"
              ></span>
              <span
                class="text-sm text-gray-60"
                v-t="
                  `supplier/pricing_item.exchange_rate.${pricing_item.exchange_rate}`
                "
              ></span>
            </div>
            <div class="flex items-center col-span-2">
              <span
                v-t="
                  'activerecord.attributes.supplier/pricing_item.upstream_support'
                "
                class="font-bold text-gray-60 me-2"
              ></span>
              <span class="text-sm text-gray-60">{{
                pricing_item.upstream_support
              }}</span>
            </div>
            <div
              class="flex items-center col-span-2 overflow-hidden text-ellipsis"
            >
              <span
                v-t="
                  'activerecord.attributes.supplier/pricing_item.upstream_support_way'
                "
                class="font-bold text-gray-60 me-2"
              ></span>
              <span
                class="text-sm text-gray-60"
                v-if="!!pricing_item.upstream_support_way.email"
                >{{
                  $t("supplier/pricing_item.upstream_support_way.email")
                }}：{{ pricing_item.upstream_support_way.email }}</span
              >
              <span
                class="text-sm text-gray-60"
                v-if="!!pricing_item.upstream_support_way.group.sms"
                >{{ $t("supplier/pricing_item.upstream_support_way.group") }}({{
                  pricing_item.upstream_support_way.group.sms
                }})：{{ pricing_item.upstream_support_way.group.name }}</span
              >
            </div>
          </div>
          <div class="flex items-center gap-10">
            <div class="flex items-center gap-4">
              <span
                v-t="
                  'activerecord.attributes.supplier/pricing_item.service_fee_percent'
                "
                class="font-bold text-gray-60 me-2"
              ></span>
              <span class="text-sm text-gray-60"
                >{{ pricing_item.service_fee_percent }}%</span
              >
            </div>
            <div class="flex items-center gap-4">
              <span
                v-t="
                  'activerecord.attributes.supplier/pricing_item.common_discount_percent'
                "
                class="font-bold text-gray-60 me-2"
              ></span>
              <span class="text-sm text-gray-60"
                >{{ pricing_item.common_discount_percent }}%</span
              >
            </div>
            <div class="flex items-center gap-4">
              <CommonModal
                class="underline btn btn-sm btn-link"
                :button-text="
                  $t('supplier/pricing_item.index.exclude_discount')
                "
                :modal-title="
                  $t('supplier/pricing_item.form.exclude_discount.modal.title')
                "
              >
                <p
                  v-for="exclude_discount in pricing_item.exclude_discounts"
                  :key="exclude_discount.id"
                >
                  {{ exclude_discount.name }}
                </p>
              </CommonModal>
            </div>
          </div>
          <div class="flex items-center gap-10">
            <span
              v-t="'activerecord.models.special_discount'"
              class="font-bold text-gray-60"
            ></span>

            <template v-if="pricing_item.special_discounts.length > 1">
              <div class="hs-tooltip [--trigger:hover] [--placement:bottom] inline-block">
                <div class="flex items-center hs-tooltip-toggle">
                  <span class="underline text-gray-60">{{ $t('supplier/pricing_item.index.multiple_special_discounts') }} ({{ pricing_item.special_discounts.length }})</span>
                  <div class="absolute z-10 flex flex-col invisible inline-block gap-2 px-4 py-3 transition-opacity bg-white border rounded shadow opacity-0 hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible" role="tooltip">
                    <p class="text-sm text-left text-gray-60" v-for="special_discount in pricing_item.special_discounts" :key="special_discount.id">{{ special_discount.name }}</p>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="pricing_item.special_discounts.length === 1">
              <span class="text-gray-60">{{ pricing_item.special_discounts[0].name }}</span>
            </template>
            <span class="text-gray-60" v-if="pricing_item.special_discounts.length === 0">-</span>
          </div>
        </div>

        <div class="col-span-2 text-left ps-4">
          <div
            v-t="'supplier/pricing_item.index.cloud_accounts'"
            class="font-bold text-gray-60 me-2"
          ></div>
          <div
            v-for="cloud_account in pricing_item.cloud_accounts.slice(0, 2)"
            :key="cloud_account.id"
            class="text-gray-60"
          >
            {{ cloud_account.account_id }}
          </div>
          <template v-if="pricing_item.cloud_accounts.length === 3">
            <div class="text-gray-60">
              {{ pricing_item.cloud_accounts[2].account_id }}
            </div>
          </template>
          <template v-if="pricing_item.cloud_accounts.length > 3">
            <div class="text-gray-60">
              {{ `...(${$t('supplier/pricing_item.index.cloud_accounts_count', { count: pricing_item.cloud_accounts.length })})` }}
            </div>
          </template>
        </div>
      </div>
    </Link>
  </div>
</template>
<script setup>
import { router } from "@inertiajs/vue3";
const props = defineProps({
  pricing_item: Object,
  product_id: String,
  contract_id: String,
});

const deleteUrl = `/products/${props.product_id}/contracts/${props.contract_id}/pricing_items/${props.pricing_item.id}`;

const deleteAction = () => {
  router.visit(deleteUrl, {
    method: "delete",
  });
};

</script>
